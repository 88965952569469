<style lang="scss" scoped>
.line {
	margin: 0.3rem 0.3rem;
	height: 1px;
	background: $line_color;
}

::v-deep .van-cell {
	padding-left: 0.3rem;
	padding-right: 0.3rem;
}

::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
	width: 30vw;
	flex: none;
}

::v-deep .cell_value {
	color: $font_color_val;
	font-size: 0.24rem;
}

::v-deep .van-field__body input {
	color: $font_color_val;
}

::v-deep .no_link .van-icon-arrow {
	color: #fff;
}

.order_pay {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: #f5f8fa;
	overflow: auto;

	.body {
		background-color: #fff;
	}
}

.tip {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.6rem 0.3rem;
	background-color: #f5f8fa;

	.icon {
		width: 0.6rem;
		height: 0.6rem;
		background: url(../../assets/icon/success.png);
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: center;
		margin-right: 0.38rem;
	}

	.text {
		font-size: 0.3rem;
		line-height: 0.3rem;
		color: $danger;
	}
}

.title_order_info {
	margin-top: 0.3rem;
}

.order_price {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 0.4rem 0.3rem 0.2rem;

	span {
		font-size: 0.24rem;
		font-weight: bold;
		line-height: 0.24rem;
	}

	.num {
		margin-right: 0.1rem;
	}

	.price {
		color: $color_active;
	}
}

.title_pay_type {
	margin-top: 0.4rem;
}

::v-deep .pay_type {
	.van-cell__left-icon {
		margin-right: 0.18rem;

		img {
			width: 0.3rem;
			height: 0.3rem;
		}
	}

	.van-cell__title {
		font-size: 0.24rem;
		color: $font_color_val;
	}

	.van-icon-success {
		border-radius: 0.06rem;
	}
}
</style>

<template>
	<div class="order_pay">
		<div class="body">
			<top-nav :showBack="!isRedict" @back="$router.go(-1)" :bgColor="$store.state.cps_brindInfo.brandColor">订单支付</top-nav>
			<div class="tip">
				<span class="icon"></span>
				<span class="text">请核对订单，点击下方支付按钮完成支付！</span>
			</div>
			<active-title class="title_order_info">订单信息</active-title>
			<van-cell-group :border="false">
				<van-cell title="订单号" :value="orderInfo.billCode" title-class="cell_title" value-class="cell_value" :border="false" />
				<van-cell title="产品名称" :value="orderInfo.name" title-class="cell_title" value-class="cell_value" :border="false" />
				<van-cell title="被保人" :value="orderInfo.person" title-class="cell_title" value-class="cell_value" :border="false" />
				<van-cell title="起保日期" :value="orderInfo.enableDate" title-class="cell_title" value-class="cell_value" :border="false" />
				<van-cell title="终保日期" :value="orderInfo.disEnableDate" title-class="cell_title" value-class="cell_value" :border="false" />
				<div class="line"></div>
				<van-cell title="保单形式" :value="orderInfo.type" title-class="cell_title" value-class="cell_value" :border="false" />
				<div class="order_price">
					<span class="num" v-text="`共${orderInfo.num}份保单`"></span>
					<span>共计：</span>
					<span class="price" v-text="`￥ ${this.$base.floatPrice(orderInfo.totalPayment)}`"></span>
				</div>
				<div class="line"></div>
			</van-cell-group>

			<active-title class="title_pay_type">支付方式</active-title>
			<van-radio-group v-model="payType">
				<van-cell-group class="pay_type">
					<!-- <van-cell v-if="env === 'zfb'" title="支付宝支付" clickable :icon="require('../../assets/icon/alpay.png')"
                        @click="payType = '1'">
                        <template #right-icon>
                            <van-radio name="1" shape="square" />
                        </template>
                    </van-cell> -->
					<van-cell title="微信支付" clickable :icon="require('../../assets/icon/wecatpay.png')" @click="payType = '2'">
						<template #right-icon>
							<van-radio name="2" shape="square" />
						</template>
					</van-cell>
				</van-cell-group>
			</van-radio-group>
			<price-buy-btn :price="orderInfo.totalPayment" @buy="pay">立即支付</price-buy-btn>
		</div>
	</div>
</template>

<script>
import { Cell, CellGroup, RadioGroup, Radio, Toast } from 'vant';
import { getWxCodePay, getWxH5Pay, getWxJSAPIPay, getWxInfo, getBillsInfo, getOpenId } from '@/request/api';
import { http_getServerTime, http_aLiPay } from '@/request/common';
import axios from 'axios';
import moment from 'moment';

export default {
	name: 'cpsOrderPay', //cps订单支付
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[RadioGroup.name]: RadioGroup,
		[Radio.name]: Radio,
		[Toast.name]: Toast,
	},
	props: {
		isRedict: {
			type: Boolean,
			require: true,
		},
	},
	created() {
		// Object.assign(this.orderInfo, this.billInfo)
		// this.init()
	},
	mounted() {
		this.init();
	},
	data() {
		return {
			orderInfo: {
				billCode: '',
				name: '',
				person: '',
				enableDate: '',
				disEnableDate: '',
				type: '电子保单',
				num: '0',
				totalPayment: '0',
			},
			payType: '2',
			openId: '',
			lastPayTime: 0, // 最晚支付时间戳

			timeStamp: '',
			nonceStr: '',
			wxpackage: '',
			signType: '',
			paySign: '',

			isReserver: '', // 是否为线下投保
			env: undefined,
		};
	},
	methods: {
		init() {
			// 获取当前系统环境
			// this.env = this.$base.getEnv()
			// this.setPayType()

			if (this.isRedict) {
				// 重定向来的，请求订单详情，渲染页面
				// this.getBillInfo()

				// 获取openid
				// this.getOpenId(this.$store.state.payCode)
				Toast('获取订单详情');
				axios.all([this.getBillInfo(), this.getOpenId(this.$store.state.cps_payCode)]).then(
					axios.spread(() => {
						Toast.clear();
					}),
				);
			} else {
				// 订单页面来的
				// 检测是否为微信环境
				if (this.isWeChat()) {
					// 微信环境获取code
					this.getWxInfo().then(({ appId, appSecret }) => {
						this.getWxCode(appId, appSecret);
					});
				} else {
					// 非微信环境
					this.getBillInfo();
				}
			}
			// if (this.isRedict) {
			//     // 重定向来的，请求订单详情、openid
			//     Toast('获取订单详情')
			//     axios.all([this.getBillInfo(), this.getOpenId(this.$store.state.payCode)]).then(axios.spread(() => {
			//         Toast.clear()
			//     }))
			// }else{
			//     // 订单页面来的
			//     if (this.env === 'wx') {
			//         // 微信,微信会自动执行重定向，所以不用获取订单详情
			//         this.getWxInfo().then(({appId, appSecret}) => {
			//             this.getWxCode(appId, appSecret)
			//         })
			//     }else {
			//         this.getBillInfo()
			//     }
			// }
		},

		// setPayType() {
		//     if (this.env === 'wx') {
		//         this.payType = '2'
		//     }else if (this.env === 'zfb') {
		//         this.payType = '1'
		//     }
		// },

		// 支付宝支付
		aLiPay() {
			let send = {
				billIdList: [this.$store.state.cps_billId],
				returnUrl: `${location.origin}/paySuccess/${this.$store.state.cps_billId}`,
				// quitUrl: location.href
			};
			http_aLiPay(send).then(res => {
				Toast.clear();
				ap.pushWindow({
					url: `${location.origin}/aLiPay`,
					data: {
						content: res,
					},
				});
			});
		},

		// 获取订单信息
		getBillInfo() {
			return new Promise(resolve => {
				getBillsInfo(this.$store.state.cps_billId).then(res => {
					this.isReserver = res.list[0].is_reserver;
					// 苹果系统不支持时间横线连接符
					// this.lastPayTime = new Date(res.list[0].billInfo.payLimitTime.replace(/-/g, '/')).getTime();
					// 有在国外使用的朋友，这里要格式化为东八区时间,moment.js已经处理了苹果的兼容性
					this.lastPayTime = moment.utc(res.list[0].billInfo.payLimitTime).subtract(8, 'h').valueOf();
					this.setPageData(res.list);
					resolve();
				});
			});
		},

		// 设置页面数据
		setPageData(list) {
			let billInfo = list[0].billInfo;
			let totalPayment = 0;
			let assuredList = [];
			list.forEach(item => {
				totalPayment += Number(item.billInfo.premium);
				assuredList = [...assuredList, ...item.billAssuredList];
			});
			billInfo.totalPayment = totalPayment;
			Object.assign(this.orderInfo, billInfo);
			let personNames = assuredList.map(item => item.name);
			this.orderInfo.person = personNames.length <= 3 ? personNames.join(',') : `${personNames.slice(0, 3).join(',')}等${personNames.length}人`;
			this.orderInfo.name = this.orderInfo.insProductName;
		},

		// 获取公众号参数,接口会返回{appid, appSecret},用来获取code
		getWxInfo() {
			return new Promise(resolve => {
				getWxInfo().then(res => {
					resolve(res);
				});
			});
		},

		// 获取code
		getWxCode(appId, appSecret) {
			let redictUrl = encodeURIComponent(window.location.href + 'Redict');
			// 因为微信该接口state参数最大128个字符，我们的参数长度超过了要求，所以选择存储在localStorage中
			let state = {
				user: this.$store.state.cps_brindInfo.userDesc,
				productId: this.$store.state.cps_productId,
				plainId: this.$store.state.cps_plainId,
				billId: this.$store.state.cps_billId,
				appId: appId,
				appSecret: appSecret,
			};
			localStorage.cps_stateInfo = JSON.stringify(state);
			let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redictUrl}&response_type=code&scope=snsapi_base#wechat_redirect`;
			window.location.replace(url);
		},

		// 拿着code从后台获取openid
		getOpenId(code) {
			return new Promise(resolve => {
				getOpenId(code).then(res => {
					this.openId = res.openid;
					resolve();
				});
			});
		},

		// 检测订单是否可以支付
		checkIsPay() {
			return new Promise(resolve => {
				Toast.loading({
					duration: 0, // 持续展示 toast
					forbidClick: true,
					message: '检查是否可以支付...',
				});
				http_getServerTime().then(res => {
					Toast.clear();
					let now = Number(res.timestamp);
					if (this.lastPayTime > now) {
						resolve();
					} else {
						Toast('该订单已失效，请重新下单');
					}
				});
			});
		},

		pay() {
			this.checkIsPay().then(() => {
				Toast.loading({
					duration: 0, // 持续展示 toast
					forbidClick: true,
					message: '获取支付信息...',
				});

				if (this.isWeChat()) {
					// 微信环境，JSAPI支付
					this.getJSAPI().then(res => {
						Toast.clear();
						this.timeStamp = res.timeStamp;
						this.nonceStr = res.nonceStr;
						this.wxpackage = res.package;
						this.signType = res.signType;
						this.paySign = res.paySign;

						// JSAPI支付
						this.jsapiPay();
					});
				} else {
					// H5支付
					this.getH5().then(url => {
						Toast.clear();
						window.open(url, '_self');
					});
				}
			});
			// this.checkIsPay().then(() => {
			//     Toast.loading({
			//         duration: 0, // 持续展示 toast
			//         forbidClick: true,
			//         message: '获取支付信息...'
			//     })

			//     if (this.env === 'wx') {
			//         // 微信环境，JSAPI支付
			//         this.getJSAPI().then(res => {
			//             Toast.clear()
			//             this.timeStamp = res.timeStamp;
			//             this.nonceStr = res.nonceStr;
			//             this.wxpackage = res.package;
			//             this.signType = res.signType;
			//             this.paySign = res.paySign;

			//             // JSAPI支付
			//             this.jsapiPay();
			//         })
			//     }else if (this.env === 'zfb') {
			//         this.aLiPay()
			//     }
			// })
		},

		// 获取H5支付信息
		getH5() {
			return new Promise(resolve => {
				getWxH5Pay(this.$store.state.cps_billId).then(res => {
					let locaUrl = window.location.href;
					let redirect_url = locaUrl.replace('cpsOrderPay', 'paySuccessAfterPay');
					let encode_redirect_url = encodeURIComponent(redirect_url);
					resolve(res.brandWCPayRequest.mWebUrl + '&redirect_url=' + encode_redirect_url);
				});
			});
		},

		//  获取JSAPI支付信息
		getJSAPI() {
			return new Promise(resolve => {
				getWxJSAPIPay(this.$store.state.cps_billId, this.openId).then(res => {
					resolve(res.brandWCPayRequest);
				});
			});
		},

		// 判断是否微信环境
		isWeChat() {
			//window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
			var ua = window.navigator.userAgent.toLowerCase();
			//通过正则表达式匹配ua中是否含有MicroMessenger字符串
			if (ua.match(/MicroMessenger/i) == 'micromessenger') {
				return true;
			} else {
				return false;
			}
		},

		// 微信JSAPI支付对象初始化
		jsapiPay() {
			if (typeof WeixinJSBridge == 'undefined') {
				if (document.addEventListener) {
					document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
				} else if (document.attachEvent) {
					document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
					document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
				}
			} else {
				this.onBridgeReady();
			}
		},

		// JSAPI支付方法
		onBridgeReady() {
			let _this = this;
			let send = {
				appId: String(this.$store.state.cps_appId),
				timeStamp: this.timeStamp,
				nonceStr: this.nonceStr,
				package: this.wxpackage,
				signType: this.signType,
				paySign: this.paySign,
			};

			WeixinJSBridge.invoke('getBrandWCPayRequest', send, function (res) {
				if (res.err_msg == 'get_brand_wcpay_request:ok') {
					// 使用以上方式判断前端返回,微信团队郑重提示：
					//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。

					let params = { protectBillUrl: undefined };
					if (this.isReserver) {
						params.text = '稍后将以短信形式发送投保信息';
					}
					_this.$router.push({
						name: 'cpspaySuccess',
						params: { protectBillUrl: undefined },
					});
				}
			});
		},
	},
};
</script>
